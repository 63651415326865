<div class="{{params.style?.class}} padding0">
    <div class="col s12 l12">
        <br>
    </div>
    <div class="col s12 l12 padding0 marginleft15">
        <div class="divbtnback">
            <mat-icon *ngIf="params.goback?.value" (click)="goback(params.goback)" class="goback left112">
                arrow_back_ios</mat-icon>
        </div>
        <div class="col s11 l11 padding0">
            <div class="divtexttitle">

                <span class="newTitle"> {{ params.title?.value | translate }} </span>
                <p class="font-16-basic" *ngIf="params.title?.value == 'Subscriptions'">
                    {{"Here is a list of all your subscription's properties. You can edit some information about your subscription, and you can also add and edit properties to it." | translate}}
                </p>
            </div>
            <div class="divbtnadd">
                <button *ngIf="params.addButton && !params.minimal"
                    class="btn-floating waves-effect waves-light addbtn"
                    tooltip="{{ params.addButton.title | translate }}" container="body" placement="right"
                    [routerLink]="[params.addButton.url]" [queryParams]="params.addButton.params">
                    <i class="material-icons">add</i>
                </button>
            </div>
            <div class="right divprogess" *ngIf="params.progressBar && params.minimal">
                <span class="textprogress">
                    {{ params.progressBar.sleep.current ? params.progressBar.sleep.current + "/" + params.progressBar.sleep.all : params.progressBar.percentage}}
                </span>
                <div class="progress" *ngIf="params.progressBar?.percentage">
                    <div class="determinate" [style.width]="params.progressBar.percentage"></div>
                </div>
            </div>
            <div class="divdesc">
                <span class="newDesc"> {{ params.description?.value | translate }} </span>
            </div>
        </div>
    </div>

</div>
