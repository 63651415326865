import { Injectable } from "@angular/core";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { ActivatedRoute } from '@angular/router';
import { Auth0Service } from "../auth0.service";

@Injectable()
export class GuesthubInterceptor implements HttpInterceptor {
  constructor(private route: ActivatedRoute, private auth: Auth0Service) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (this.auth.isAuthenticated) {

      const guesthubReq = req.clone({
        headers: req.headers.set(
          "Guesthub-Context",
          JSON.stringify({
            properties: [this.auth.userProfile.property],
            user: this.auth.userProfile.id
          })
        )
      });
      const authReq = guesthubReq.clone({
        headers: guesthubReq.headers.set(
          "Authorization",
          `Bearer ${this.auth.getToken()}`
        )
      });
      return next.handle(authReq);
    }
    return next.handle(req);
  }
}
