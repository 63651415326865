import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, RouterLink } from "@angular/router";
import { Auth0Service } from "../../../auth0.service";
import { NgIf } from "@angular/common";

@Component({
    selector: "app-guesthub-navigation-top",
    styleUrls: ["styles.scss"],
    templateUrl: "template.html",
    standalone: true,
    imports: [RouterLink, NgIf]
})
export class TopNavigationComponent implements OnInit {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public auth: Auth0Service
  ) {
  }

  ngOnInit() {}

}
