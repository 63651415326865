import { enableProdMode, importProvidersFrom } from '@angular/core';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { HeaderTitleModule } from './app/components/navigation/top/header-title/module';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { AppRoutingModule } from './app/app-routing.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { Auth0Service } from './app/auth0.service';
import { GuesthubInterceptor } from './app/services/interceptor';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient, HttpClient } from '@angular/common/http';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { UrlBuilderService } from './app/services/url-builder.service';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TabsModule } from 'ngx-bootstrap/tabs';


if (environment.production) {
    enableProdMode();
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

bootstrapApplication(
    AppComponent,
    {
        providers: [
            importProvidersFrom(BrowserModule, TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: HttpLoaderFactory,
                    deps: [HttpClient],
                },
            }), AppRoutingModule, SimpleNotificationsModule.forRoot(), HeaderTitleModule, PaginationModule.forRoot(), TooltipModule.forRoot(), TabsModule.forRoot()),
            UrlBuilderService,
            provideEnvironmentNgxMask(),
            {
                provide: HTTP_INTERCEPTORS,
                useClass: GuesthubInterceptor,
                multi: true,
            },
            Auth0Service,
            provideAnimations(),
            provideHttpClient(withInterceptorsFromDi()),
            provideAnimations()
        ]
    })
    .catch(err => console.error(err));
