import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { NavigationHeaderTitleService } from './service';
import { environment } from 'src/environments/environment';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';


@Component({
  selector: 'app-navigation-header-title',
  templateUrl: './template.html',
  styleUrls: ['./styles.scss'],
  standalone: true,
  imports: [NgIf, TooltipModule, RouterLink, TranslateModule, MatIconModule]
})
export class MyComponent implements OnInit {
  params: any = {};
  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private service: NavigationHeaderTitleService
  ) { }

  ngOnInit() {
    if (this.params) {
      this.translate.use(this.params.lang || 'en');
      this.params = this.service.params;

    }
    /*this.router.events.subscribe((val) => {
      this.params = this.service.params;
    });*/
    this.service.subscribe(options => this.splitUriAndQuerys(options));
  }

  splitUriAndQuerys(options: any) {
    if (options) {
      this.params = options;
      if (options.goback && options.goback.value && options.goback.value.split('?').length > 1) {
        this.params.goback.params = JSON.parse('{"' + decodeURI(options.goback.value.split('?')[1]).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
        this.params.goback.value = options.goback.value.split('?')[0];
      } else if (this.params.goback && this.params.goback.value && this.params.goback.params) {
        this.params.goback.value = options.goback && options.goback.value ? options.goback.value : null;
        this.params.goback.params = {};
      }
      if (this.params.progressBar) {
        this.params.progressBar = Object.assign({}, this.params.progressBar, {
          percentage: this.params.progressBar.sleep ?
            ((this.params.progressBar.sleep.current * 100) / this.params.progressBar.sleep.all) + "%"
            : this.params.progressBar.percentage
        });
      }
    } else {
      this.params = this.service.params;
    }
  }

  goback(goback) {
    if (goback.value.search('/settings/features') > -1) {
      window.parent.postMessage({
        returnTo: {
          value: goback.value
        }
      }, "*");
    } else if (goback.value.search('/app-web') > -1) {
      window.location.href = environment.guesthub.app.web.url;
    } else {
      this.router.navigate([goback.value], { queryParams: goback.params });
    }
  }
}