<div class="row" *ngIf="!load">
  <app-guesthub-navigation-top *ngIf="!hiddenNavBar"></app-guesthub-navigation-top>
  <div class="row" id="routeroutlet">
    <app-navigation-header-title></app-navigation-header-title>
    <router-outlet></router-outlet>
  </div>
</div>
<div class="row container" *ngIf="load">
  <div class="col s12 l12 xl12" style="z-index: 10000; position: absolute">
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div style="text-align: center; padding-bottom: 500px !important">
      <img src="assets/logo-guesthub.png" />
      <br /><br />
      <div class="preloader-wrapper big active">
        <div class="spinner-layer spinner-green-only">
          <div class="circle-clipper left">
            <div class="circle"></div>
          </div>
          <div class="gap-patch">
            <div class="circle"></div>
          </div>
          <div class="circle-clipper right">
            <div class="circle"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
