import { Component, OnInit, forwardRef } from '@angular/core';
import { SubscriptionsListPageService } from './service';
import { TranslateModule } from '@ngx-translate/core';
import { Router, RouterLink } from '@angular/router';
import { NavigationHeaderTitleService } from '../../components/navigation/top/header-title/service';
import { Auth0Service } from "../../auth0.service";

@Component({
  selector: 'app-guesthub-subscriptions',
  templateUrl: './template.html',
  styleUrls: ['./styles.scss'],
  standalone: true,
  imports: [NgClass, NgFor, RouterLink, TranslateModule, forwardRef(() => FilterPipe)],
  providers: [
    SubscriptionsListPageService
  ],
})
export class SubscriptionsListPageComponent implements OnInit {
  suscriptions: any;
  tableandthumbnails: boolean = true;
  defaultVisible: boolean = false;
  searchText: any = '';

  constructor(
    private SubscriptionsListPageService: SubscriptionsListPageService,
    private router: Router,
    private auth: Auth0Service,
    protected navigationHeaderTitleService: NavigationHeaderTitleService
  ) { }

  ngOnInit() {
    this.navigationHeaderTitleService.next({
      title: {
        value: "Subscriptions"
      },
      description: {
        value: null
      },
      style: {
        class: "col l8 offset-l2 m12 s12"
      },
      goback: {
        value: "/app-web"
      }
    });

    this.SubscriptionsListPageService.execute()
      .then(subscriptions => {
        if (subscriptions.length == 0) {
          this.auth.logout();
        } else if (subscriptions.length == 1) {
          this.router.navigate(['/subscriptions/' + subscriptions[0].id]);
        } else {
          this.suscriptions = subscriptions;
        }
      })
      .catch(err => console.error(err));
  }

  changethumbnails(): void {
    if (this.tableandthumbnails == false) {
      this.tableandthumbnails = true
    }
  }

  changetable(): void {
    if (this.tableandthumbnails == true) {
      this.tableandthumbnails = false
    }
  }

  onKeySearch(e) {
    this.searchText = e;
    if (e.trim().length > 0) {
      this.defaultVisible = true;

    } else {
      this.defaultVisible = false;
    }
  }

}

import { Pipe, PipeTransform } from '@angular/core';
import { NgClass, NgFor } from '@angular/common';
@Pipe({
  name: 'filter',
  standalone: true
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], value: string, label: string): any[] {
    if (!items) return [];
    if (!value) return items;
    if (value == '' || value == null) return [];
    return items.filter(e => e[label].toLowerCase().indexOf(value) > -1);

  }
}
