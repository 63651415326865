import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlBuilderService } from '../../services/url-builder.service';

@Injectable()
export class SubscriptionsListPageService {

    constructor(
        private http: HttpClient,
        private urlbuilder: UrlBuilderService
    ) { }

    /** Method concatenates strings to get Get All Request URL
     * @returns Get All Request URL
     */

    execute() {
        return this.http.get<any>(this.urlbuilder.subscriptions.list())
            .toPromise()
    }
  
}
