import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoutingModule } from './routing.module';
import { MyComponent } from './component';
import { NavigationHeaderTitleService } from './service';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
    imports: [
        CommonModule,
        RoutingModule,
        TranslateModule,
        TooltipModule,
        MyComponent
    ],
    exports: [
        MyComponent
    ],
    providers: [
        NavigationHeaderTitleService
    ],
})
export class HeaderTitleModule { }
