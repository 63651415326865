import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SubscriptionsListPageComponent } from './subscriptions/list/component';
import { SubscriptionDetailComponent } from './subscriptions/detail/component';
import { SubscriptionUpdateComponent } from './subscriptions/update/component';
import { PropertyCreateComponent } from './subscriptions/property/create/component';
import { MyComponent } from './rooms/component';

const routes: Routes = [
  {
    path: 'subscriptions',
    component: SubscriptionsListPageComponent,

  },
  {
    path: "subscriptions/:id", loadComponent: () => import("./subscriptions/detail/component").then(c => c.SubscriptionDetailComponent)
  },
  {
    path: "subscriptions/:subscriptionId/property/:propertyId/towers", loadComponent: () => import("./rooms/component").then(c => c.MyComponent)
  },
  {
    path: "subscriptions/:id/update", loadComponent: () => import("./subscriptions/update/component").then(c => c.SubscriptionUpdateComponent)
  },
  {
    path: "subscriptions/:id/property/create", loadComponent: () => import("./subscriptions/property/create/component").then(c => c.PropertyCreateComponent)
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'subscriptions'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
