import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, RouterOutlet } from "@angular/router";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { Auth0Service } from "./auth0.service";
import { MyComponent } from "./components/navigation/top/header-title/component";
import { TopNavigationComponent } from "./components/navigation/top/component";
import { NgIf } from "@angular/common";
@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    standalone: true,
    imports: [
        NgIf,
        MyComponent,
        TopNavigationComponent,
        RouterOutlet,
    ],
})
export class AppComponent implements OnInit {
  public load: boolean;
  shouldDisplayOverlay: boolean = true;
  hiddenNavBar: boolean = false;

  constructor(
    private route: ActivatedRoute,
    translate: TranslateService,
    private auth: Auth0Service
  ) {
    this.load = true;
    translate.setDefaultLang("en");
    if (localStorage.getItem("lang")) {
      translate.use(JSON.parse(localStorage.getItem("lang")).lang);
    } else {
      translate.use(window.navigator.language.substr(0, 2));
    }

    this.route.queryParams.subscribe((params) => {
      if (params.lang) {
        translate.use(params.lang);
      }
      params.goback ? localStorage.setItem("goback", params.goback) : null;
      this.hiddenNavBar = params.authapp ? true : false;
    });
  }

  ngOnInit() {
    this.load = true;
    if (window.self !== window.top) {
      this.route.queryParams.subscribe(queryParams => {
        if (window.self !== window.top && queryParams.userId && queryParams.propertyId) {
          this.auth.isAuthenticated = true;
          this.auth.userProfile = { id: parseInt(queryParams.userId), property: parseInt(queryParams.propertyId), hashId: queryParams.hashId };
          this.auth.setToken(queryParams.token);
          this.load = false;
        }
      });
    } else {
      this.auth.handleAuthentication()
        .then(() => {
          this.load = false;
        });
    }
  }
}
