<div class="col l8 offset-l2 m12 s12">
  <div class="cont__search col xl12 col l12 col m12 col s12">
    <div class="search padding0 margin0 col xl3 col l3 col m3 col s12 ">
      <i class="material-icons search__icon" [ngClass]="{'icon-search-hidden':searchText || defaultVisible }">search</i>
      <input id="search" type="text" placeholder="{{'Search' | translate}}" class="validate search__input "
        (keyup)="onKeySearch($event.target.value)" [value]="searchText" autocomplete="off" />
    </div>
  </div>
  <div class="col s12 l12 m12 xl12 padding0 cont__list">
    <div class=" padding0" *ngFor="let suscription of suscriptions | filter : searchText : 'name' ; let i = index"
      [routerLink]="[suscription.id]">
      <div class="card ">
        <div class="cardImg__cont">
          <img class="card__img" [src]="suscription.logo" />
        </div>
        <div class="cardText__cont">
          <span class="card__text ">{{suscription.name}}</span>
        </div>
      </div>
    </div>
  </div>
</div>