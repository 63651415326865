<div class="containerbase col xl12  l12  m12  s12 padding0 ">
  <div class="col xl7  l6  m5  s6 padding-bottom-div">
    <a routerLink="/subscriptions" class="efecto">
      <img src="assets/GH_Logo.png" class="iconGuest" />
    </a>
  </div>

  <div class="col xl5 l6  m7  s6 padding-bottom-div right padding-left-flot">
    <div class="flot margin-right-flot margin-top10">
      <div class="pictureavtar-logout" container="body" placement="bottom" (click)="auth.logout()">
        <i class="material-icons right">exit_to_app</i>
      </div>
    </div>

    <div class="flot margin-right-flot margin-top10">
      <div class="pictureavtar" container="body" placement="bottom">
        <img *ngIf="auth.userProfile" [src]="auth.userProfile.picture" class="circle" width="32">
      </div>
    </div>
  </div>
</div>